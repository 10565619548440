import { LoadingButton, useModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Button } from "@mui/material";
import {
  MissingDocumentsAlert,
  OverworkBookingAlert,
  WaitingStripeResponseAlert,
} from "@src/app/dayView/Alerts";
import { ShiftItemCategory } from "@src/app/dayView/model";
import { AppV2AccountRoutes } from "@src/app/routing/constant/appV2AccountRoutes";
import { ActionType } from "@src/app/store/session";
import { generateDocumentDetailsPath } from "@src/appV2/Accounts/DocumentDetails/generateDocumentDetailsPath";
import { DocumentRequirementType } from "@src/appV2/Accounts/Documents";
import { useGetMissingRequirements } from "@src/appV2/Accounts/Documents/api/useGetMissingRequirements";
import { useGetMissingRequirementsForDate } from "@src/appV2/Accounts/Documents/api/useGetMissingRequirementsForDate";
import { useGetQualificationForDocumentCheck } from "@src/appV2/Accounts/Documents/hooks/useGetQualificationForDocumentCheck/useGetQualificationForDocumentCheck";
import {
  MissingDocumentsAlertSource,
  logDocumentsDropoffFlowClickedViewDocuments,
  logDocumentsDropoffFlowViewedMissingDocumentsAlert,
} from "@src/appV2/Accounts/Documents/logUtils";
import { useToast } from "@src/appV2/lib";
import {
  APP_V2_APP_EVENTS,
  APP_V2_USER_EVENTS,
  logError,
  logEvent,
} from "@src/appV2/lib/analytics";
import {
  BookabilityAttributes,
  BookabilityDecision,
  BookabilityUnmetCriteria,
} from "@src/appV2/OpenShifts/ShiftAction";
import { AssessmentBeforeBookingDialog } from "@src/appV2/SkillsAssessment/components/AssessmentBeforeBookingDialog";
import { PaymentServiceAccountStatus } from "@src/appV2/Worker/api/types";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { type Shift } from "@src/lib/interface/src";
import pluralize from "pluralize";
import { useRef } from "react";
import { useDispatch } from "react-redux";
import { generatePath, useHistory, useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

export interface BlockedShiftButtonProps {
  shiftBookability: BookabilityAttributes & { decision: BookabilityDecision.BLOCKED };
  shift: Shift;
}

export function BlockedShiftButton(props: BlockedShiftButtonProps) {
  const { shiftBookability, shift } = props;
  const documentsDropOffFlowId = useRef<string | undefined>(undefined);

  const worker = useDefinedWorker();

  const { showErrorToast, showWarningToast } = useToast();
  const storeDispatch = useDispatch();
  const location = useLocation();

  const {
    missingDocuments,
    pendingDocuments,
    isLoading: isLoadingMissingOrPendingDocuments,
  } = useGetMissingRequirements(
    {
      workerId: worker.userId,
      workplace: {
        id: shift.facility?.userId ?? shift.facilityId,
        type: shift.facility?.type,
        msa: shift.facility?.fullAddress?.metropolitanStatisticalArea,
        state: shift.facility?.fullAddress?.state,
        region: shift.facility?.fullAddress?.region,
      },
    },
    {
      enabled: isDefined(shift.facility),
    }
  );

  const {
    mutateAsync: getMissingRequirementsForDate,
    data: missingRequirementsForDate,
    isLoading: isGetMissingRequirementsForDateLoading,
    isSuccess: isGetMissingRequirementsSuccess,
  } = useGetMissingRequirementsForDate();

  const missingDocumentsForDate = isGetMissingRequirementsSuccess
    ? missingRequirementsForDate.data.hcpMissingRequirementsForDate.map((params) => ({
        reqId: params.reqId,
        name: params.name,
        visibleToHCP: params.visibleToHCP,
        requirementWorkflowType: params.requirementWorkflowType,
      }))
    : [];

  const missingDocumentsSet =
    missingDocuments.size > 0 ? missingDocuments : new Set(missingDocumentsForDate);

  const history = useHistory();

  const {
    modalIsOpen: waitingStripeResponseModalIsOpen,
    openModal: openWaitingStripeResponseModal,
    closeModal: closeWaitingStripeResponseModal,
  } = useModalState();

  const {
    modalIsOpen: overworkBookingAlertIsOpen,
    openModal: openOverworkBookingAlert,
    closeModal: closeOverworkBookingAlert,
  } = useModalState();

  const {
    modalIsOpen: missingDocumentsAlertIsOpen,
    openModal: openMissingDocumentsAlert,
    closeModal: closeMissingDocumentsAlert,
  } = useModalState();

  const assessmentBeforeBookingModalState = useModalState();
  const blockedDueToAssessmentMetadata =
    shiftBookability?.decision === BookabilityDecision.BLOCKED &&
    shiftBookability?.unmetCriteriaWithMetadata?.[0].unmetCriteria ===
      BookabilityUnmetCriteria.WORKER_HAS_NOT_PASSED_ASSESSMENTS
      ? shiftBookability?.unmetCriteriaWithMetadata?.[0].metadata
      : undefined;

  const { getQualificationForDocumentCheck } = useGetQualificationForDocumentCheck();

  return (
    <>
      {shiftBookability.unmetCriteria[0] ===
      BookabilityUnmetCriteria.WORKER_MISSING_REQUIREMENTS ? (
        <LoadingButton
          variant="outlined"
          size="small"
          fullWidth
          isLoading={isGetMissingRequirementsForDateLoading}
          disabled={isLoadingMissingOrPendingDocuments}
          onClick={async () => {
            logEvent(APP_V2_USER_EVENTS.TAPPED_BUTTON_FOR_BLOCKED_BOOKABILITY_STATUS, {
              unmetCriteria: BookabilityUnmetCriteria.WORKER_MISSING_REQUIREMENTS,
              shiftId: shift._id,
            });

            documentsDropOffFlowId.current = uuidv4();

            if (missingDocuments.size + pendingDocuments.size > 0) {
              openMissingDocumentsAlert();
              logDocumentsDropoffFlowViewedMissingDocumentsAlert({
                missingDocuments,
                shiftId: shift._id ?? "",
                facilityId: shift.facilityId ?? "",
                documentsDropOffFlowId: documentsDropOffFlowId.current,
                source: MissingDocumentsAlertSource.BLOCKED_SHIFT,
              });
              return;
            }

            const missingDocumentsForDate = await getMissingRequirementsForDate({
              hcfId: shift.facilityId ?? "",
              date: shift.end ?? "",
              qualification: getQualificationForDocumentCheck({
                shiftQualificationRequirement: shift.agentReq ?? "",
                selectedWorkerQualification: worker?.preference?.qualification ?? "",
              }),
            });

            if (missingDocumentsForDate?.data.hcpMissingRequirementsForDate.length > 0) {
              openMissingDocumentsAlert();
              logDocumentsDropoffFlowViewedMissingDocumentsAlert({
                missingDocuments,
                shiftId: shift._id ?? "",
                facilityId: shift.facilityId ?? "",
                documentsDropOffFlowId: documentsDropOffFlowId.current,
                source: MissingDocumentsAlertSource.BLOCKED_SHIFT,
              });
            } else {
              // Ideally we should never reach here
              showWarningToast("You have no missing documents for this shift.");
              logError(APP_V2_APP_EVENTS.SHIFT_BLOCKED_DUE_TO_EMPTY_MISSING_DOCUMENTS, {
                error: new Error(
                  "Shift blocked due to missing documents but no missing documents found"
                ),
                metadata: {
                  shiftId: shift._id,
                  workerId: worker.userId,
                  facilityId: shift.facilityId ?? "",
                  date: shift.end ?? "",
                  qualification: shift.agentReq ?? "",
                },
              });
            }
          }}
        >
          <b>
            {missingDocuments.size > 0
              ? `Upload ${missingDocuments.size} Required ${pluralize(
                  "Document",
                  missingDocuments.size
                )}`
              : "Upload Required Documents"}
          </b>
        </LoadingButton>
      ) : null}

      {shiftBookability.unmetCriteria[0] === BookabilityUnmetCriteria.WORKER_PENDING_DOCUMENTS ? (
        <Button
          variant="outlined"
          size="small"
          fullWidth
          disabled={isLoadingMissingOrPendingDocuments}
          onClick={async () => {
            logEvent(APP_V2_USER_EVENTS.TAPPED_BUTTON_FOR_BLOCKED_BOOKABILITY_STATUS, {
              unmetCriteria: BookabilityUnmetCriteria.WORKER_PENDING_DOCUMENTS,
            });
            openMissingDocumentsAlert();
          }}
        >
          <b>View Pending Documents</b>
        </Button>
      ) : null}

      {shiftBookability.unmetCriteria[0] === BookabilityUnmetCriteria.SHIFT_OVERWORKING ? (
        <Button
          variant="outlined"
          size="small"
          fullWidth
          onClick={async () => {
            logEvent(APP_V2_USER_EVENTS.TAPPED_BUTTON_FOR_BLOCKED_BOOKABILITY_STATUS, {
              unmetCriteria: BookabilityUnmetCriteria.SHIFT_OVERWORKING,
            });
            openOverworkBookingAlert();
          }}
        >
          <b>Unable to book shift</b>
        </Button>
      ) : null}

      {shiftBookability.unmetCriteria[0] === BookabilityUnmetCriteria.WORKER_PAYMENTS_DISABLED ? (
        <Button
          variant="outlined"
          size="small"
          fullWidth
          onClick={async () => {
            logEvent(APP_V2_USER_EVENTS.TAPPED_BUTTON_FOR_BLOCKED_BOOKABILITY_STATUS, {
              unmetCriteria: BookabilityUnmetCriteria.WORKER_PAYMENTS_DISABLED,
            });

            const isAgentPendingInPaymentService = worker.canBeOnboardedInStripe
              ? [
                  PaymentServiceAccountStatus.ACCOUNT_CREATED,
                  PaymentServiceAccountStatus.ACCOUNT_NEEDS_UPDATE,
                  PaymentServiceAccountStatus.INSTANT_PAYOUT_PENDING,
                  PaymentServiceAccountStatus.STANDARD_PAYOUT_PENDING,
                ].includes(worker.paymentAccountInfo?.status as PaymentServiceAccountStatus)
              : false;

            if (isAgentPendingInPaymentService) {
              history.push("/home/onboardingPayments");
            } else {
              openWaitingStripeResponseModal();
            }
          }}
        >
          <b>Enable Payments</b>
        </Button>
      ) : null}

      {shiftBookability.unmetCriteria[0] === BookabilityUnmetCriteria.WORKER_UNSIGNED_AGREEMENT ? (
        <Button
          variant="outlined"
          size="small"
          fullWidth
          onClick={async () => {
            logEvent(APP_V2_USER_EVENTS.TAPPED_BUTTON_FOR_BLOCKED_BOOKABILITY_STATUS, {
              unmetCriteria: shiftBookability.unmetCriteria,
            });

            storeDispatch({
              type: ActionType.SHOW_IC_AGREEMENT_POPUP,
            });
          }}
        >
          <b>Sign Agreement</b>
        </Button>
      ) : null}

      {shiftBookability.unmetCriteria[0] ===
      BookabilityUnmetCriteria.WORKER_DISABLED_TEST_ACCOUNT ? (
        <Button
          variant="outlined"
          size="small"
          fullWidth
          onClick={async () => {
            logEvent(APP_V2_USER_EVENTS.TAPPED_BUTTON_FOR_BLOCKED_BOOKABILITY_STATUS, {
              unmetCriteria: shiftBookability.unmetCriteria,
            });
            showErrorToast("You cannot book this shift with a test account");
          }}
        >
          <b>Disabled (Test Account)</b>
        </Button>
      ) : null}

      {shiftBookability.unmetCriteria[0] ===
      BookabilityUnmetCriteria.WORKER_HAS_NOT_PASSED_ASSESSMENTS ? (
        <Button
          variant="outlined"
          size="small"
          fullWidth
          onClick={async () => {
            logEvent(APP_V2_USER_EVENTS.TAPPED_BUTTON_FOR_BLOCKED_BOOKABILITY_STATUS, {
              unmetCriteria: shiftBookability.unmetCriteria,
            });
            assessmentBeforeBookingModalState.openModal();
          }}
        >
          <b>Skills Assessment Required</b>
        </Button>
      ) : null}

      {shiftBookability.unmetCriteria.length === 0 ||
      ![
        BookabilityUnmetCriteria.WORKER_MISSING_REQUIREMENTS,
        BookabilityUnmetCriteria.WORKER_PENDING_DOCUMENTS,
        BookabilityUnmetCriteria.SHIFT_OVERWORKING,
        BookabilityUnmetCriteria.WORKER_PAYMENTS_DISABLED,
        BookabilityUnmetCriteria.WORKER_UNSIGNED_AGREEMENT,
        BookabilityUnmetCriteria.WORKER_DISABLED_TEST_ACCOUNT,
        BookabilityUnmetCriteria.WORKER_HAS_NOT_PASSED_ASSESSMENTS,
      ].includes(shiftBookability.unmetCriteria[0]) ? (
        <Button
          variant="outlined"
          size="small"
          fullWidth
          onClick={() => {
            logEvent(APP_V2_USER_EVENTS.TAPPED_SHIFT_UNAVAILABLE_FOR_BLOCKED_BOOKABILITY_STATUS, {
              bookabilityDecision: shiftBookability.decision,
              unmetCriteria: shiftBookability.unmetCriteria,
              shiftId: shift._id,
            });
            showErrorToast("Sorry, this shift is no longer available");
          }}
        >
          <b>Shift Unavailable</b>
        </Button>
      ) : null}

      <MissingDocumentsAlert
        shift={shift}
        isOpen={missingDocumentsAlertIsOpen}
        missingDocuments={missingDocumentsSet}
        pendingDocuments={pendingDocuments}
        category={ShiftItemCategory.MISSING_CBH_DOCUMENTS}
        expiringInFuture={false}
        onConfirm={() => {
          logDocumentsDropoffFlowClickedViewDocuments({
            numberOfMissingDocuments: missingDocumentsSet.size,
            documentsDropOffFlowId: documentsDropOffFlowId.current ?? "",
            source: MissingDocumentsAlertSource.BLOCKED_SHIFT,
          });
          if (missingDocumentsSet.size === 1) {
            const documentDetailsPath = generateDocumentDetailsPath({
              hcfId: shift.facilityId,
              requirement: missingDocumentsSet.values().next().value?.reqId,
              requirementStatus: DocumentRequirementType.MISSING,
            });
            history.push(documentDetailsPath, {
              returnUrl: location.pathname,
              documentsDropOffFlowId: documentsDropOffFlowId.current,
            });
          } else if (missingDocuments.size > 1) {
            history.push(
              generatePath(AppV2AccountRoutes.HCF_DOCUMENT_BY_ID_AND_NAME, {
                hcfId: shift?.facilityId,
                hcfName: shift?.facility?.name,
              }),
              {
                returnUrl: location.pathname,
                documentsDropOffFlowId: documentsDropOffFlowId.current,
              }
            );
          } else {
            history.push(
              generatePath(AppV2AccountRoutes.HCF_DOCUMENT_BY_ID_AND_NAME, {
                hcfId: shift?.facilityId,
                hcfName: shift?.facility?.name,
              }),
              {
                returnUrl: location.pathname,
                documentsDropOffFlowId: documentsDropOffFlowId.current,
              }
            );
          }
        }}
        onDidDismiss={closeMissingDocumentsAlert}
      />

      <WaitingStripeResponseAlert
        isOpen={waitingStripeResponseModalIsOpen}
        onDidDismiss={closeWaitingStripeResponseModal}
      />

      <OverworkBookingAlert
        isOpen={overworkBookingAlertIsOpen}
        onDidDismiss={closeOverworkBookingAlert}
      />

      {isDefined(blockedDueToAssessmentMetadata) && (
        <AssessmentBeforeBookingDialog
          modalState={assessmentBeforeBookingModalState}
          metadata={blockedDueToAssessmentMetadata}
        />
      )}
    </>
  );
}
